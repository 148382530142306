import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import LooksOne from "@material-ui/icons/LooksOne";
import LooksTwo from "@material-ui/icons/LooksTwo";
import Looks3 from "@material-ui/icons/Looks3";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import pillsStyle from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.jsx";
import typea from "../../../assets/img/typea.png";
import typeb from "../../../assets/img/typeb.png";
import typec from "../../../assets/img/typec.png";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";

class SectionPills extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="navigation-pills">
            <GridContainer>
            {/* <GridItem xs={12} sm={12} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={locationimage} className={classes.homeimage} />
          </div>
          <div className={classes.paddingone}></div>
            </GridItem> */}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <NavPills
                  color="info"
                  horizontal={{
                    tabsGrid: { xs: 12, sm: 2, md: 2 },
                    contentGrid: { xs: 12, sm: 10, md: 10 }
                  }}
                  tabs={[
                    {
                      tabButton: "Unit Type A",
                      tabIcon: LooksOne,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={5}>
                        <span>
                          <p>
                         Minimum 1,250 sqft and multiples of
                          </p>
                          <br />
                          <p>
                         - 6 metre clear eaves height
                          </p>
                          <p>
                          - Full height roller shutter loading doors
                          </p>
                          <p>
                          - 37.5Kn floor loading
                          </p>
                          <p>
                          - 15% warehouse roof lights
                          </p>
                          <p>
                          - Built welfare blocks inc. WC and wet pantry
                          </p>
                          <p>
                          - Ability to build mezzanine
                          </p>
                          <p>
                            - option to have office accommodation built in and fitted by the developer and rentalised
                          </p>
                        </span>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={typea} className={classes.homeimage} />
          </div>
            </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Unit Type B",
                      tabIcon: LooksTwo,
                      tabContent: (
                        <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                        <span>
                              <p>
                              Minimum 1,550 sqft and multiples of
                          </p>
                          <br />
                          <p>
                         - 6 metre clear eaves height
                          </p>
                          <p>
                          - Full height roller shutter loading doors
                          </p>
                          <p>
                          - 37.5Kn floor loading
                          </p>
                          <p>
                          - 15% warehouse roof lights
                          </p>
                          <p>
                          - Built welfare blocks inc. WC and wet pantry
                          </p>
                          <p>
                          - Ability to build mezzanine
                          </p>
                          <p>
                            - option to have office accommodation built in and fitted by the developer and rentalised
                          </p>
                        </span>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={typeb} className={classes.homeimage} />
          </div>
            </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Unit Type C",
                      tabIcon: Looks3,
                      tabContent: (
                        <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                        <span>
                             <p>
                             Minimum 10,000 sqft maximum 35,000 sqft
                          </p>
                          <br />
                          <p>
                         - 8 metre clear eaves height
                          </p>
                          <p>
                          - Electric full height roller shutter loading doors
                          </p>
                          <p>
                          - 50Kn floor loading
                          </p>
                          <p>
                          - 15% warehouse roof lights
                          </p>
                          <p>
                          -  Option to have built mezzanine offices
                          </p>
                          <p>
                            - option to have office accommodation built in and fitted by the developer and rentalised
                          </p>
                        </span>
                         </GridItem>
                         <GridItem xs={12} sm={12} md={6}>
           <div className={classes.imagewrap}>
           <img alt="..." src={typec} className={classes.homeimage} />
           </div>
             </GridItem>
                         </GridContainer>
                      )
                    }
                  ]}
                />

<div className={classes.paddinghalf}></div>
<div className={classes.buttonwrap}>
              <DownloadButton/>
              </div>
              <div className={classes.paddingone}></div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(pillsStyle)(SectionPills);
